import { formatTimeUntilNow } from '@/lib/date';
import clsx from 'clsx';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React, { FC } from 'react';

import { BlogPost } from '../../../graphql-types';
import './blog-post-preview.css';

type BlogPostPreviewProps = {
  className?: string;
  post: Pick<
    BlogPost,
    | 'excerpt'
    | 'timeToRead'
    | 'path'
    | 'image'
    | 'title'
    | 'publishedDate'
    | 'author'
  >;
  displayAuthor?: boolean;
};

const BlogPostPreview: FC<BlogPostPreviewProps> = ({
  className,
  post: { excerpt, timeToRead, path, image, title, publishedDate, author },
  displayAuthor,
}) => (
  <div className={clsx('blog-post-preview', className)}>
    <Link to={path} className="no-underline">
      <div className="flex-1 -mx-6 -mt-10 mb-4">
        {image && (
          <BackgroundImage
            Tag="div"
            className="blog-post-image"
            {...convertToBgImage(getImage(image as any))}
          >
            {' '}
          </BackgroundImage>
        )}
      </div>

      <div className="p-1">
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-xs text-gray-500">
          {formatTimeUntilNow(publishedDate)}
          {displayAuthor && author ? ` by ${author.frontmatter.name}` : ''}
          {` - ${timeToRead} min read`}
        </p>
        <div className="my-2">{excerpt}</div>

        <p className="underline">Read more...</p>
      </div>
    </Link>
  </div>
);

export default BlogPostPreview;
