import SEO from '@/components/SEO';
import SocialIcon from '@/components/SocialIcon';
import BlogPostPreview from '@/components/blog/BlogPostPreview';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import Layout from '../components/Layout';

const TeamMemberPage: FC<PageProps<any>> = ({ data: { teamMember } }) => {
  const {
    html,
    excerpt,
    recentPosts,
    frontmatter: {
      path,
      name,
      jobTitle,
      image,
      seoImage,
      profileUrl,
      profileType,
      email,
    },
  } = teamMember;

  function renderBlogPosts() {
    return (
      <div className="p-4">
        <h2 className="mt-4 mb-11 font-semibold text-4xl">Recent posts</h2>

        <div className="flex flex-row flex-wrap justify-around">
          {recentPosts.map((post) => (
            <BlogPostPreview key={post.path} post={post} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <SEO
        title={`${name}'s profile`}
        description={excerpt}
        image={{
          src: seoImage.childImageSharp.gatsbyImageData.images.fallback.src,
          width: seoImage.childImageSharp.gatsbyImageData.width,
          height: seoImage.childImageSharp.gatsbyImageData.height,
        }}
        url={path}
      />

      <div className="container m-auto">
        <section className="mb-20">
          <h1 className="header-1 text-left p-4 pt-24 lg:pt-32">{name}</h1>
          <div className="pl-4 text-gray-400 text-xl pb-12 lg:pb-24">
            {jobTitle}
          </div>

          <div className="flex flex-col md:flex-row items-center mb-8">
            <div className="flex flex-col items-center md:w-1/2 lg:w-1/3">
              <GatsbyImage
                className="rounded-full mb-4"
                alt={name}
                image={getImage(image)}
              />
              <a
                className="flex items-center mb-4"
                target="_blank"
                rel="noopener noreferrer"
                href={profileUrl}
              >
                Follow me on{' '}
                <SocialIcon className="ml-2" platform={profileType} />
              </a>
              <p>
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            </div>

            <div className="md:ml-8 md:w-1/2 lg:w-2/3">
              <h2 className="font-semibold text-4xl mb-6">Bio</h2>
              <div
                className="generated-html"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>

          {!!(recentPosts && recentPosts.length) && renderBlogPosts()}
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamMemberPageQuery($id: String!) {
    teamMember: markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        path
        name
        jobTitle
        profileUrl
        profileType
        email
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        seoImage: image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 628, formats: [AUTO])
          }
        }
      }
      recentPosts {
        id
        excerpt
        timeToRead
        title
        path
        publishedDate
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              # https://github.com/gatsbyjs/gatsby/issues/12552
              # placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export default TeamMemberPage;
